import React from "react"

import Layout from "../basic"
import SEO from '../../components/seo'
import SearchGrid from 'components/searchgrid'

const BlogPostAll = ({ data, pageContext }) => {
	// pageContext.header = 'home_hero.png';
	let searchCTA = 'Search';

	// Looping through listings to get topics
	const topics = data.allBlogTopic.edges;
	var topicFilter = [];
	var tFilter = {};
	topics.forEach(cf => {
		tFilter = { value: cf.node.id, label: cf.node.name };
		topicFilter.push(tFilter);
	})

	const filterOptions = [
		{
			name: 'Topic',
			placeholder: 'Topic',
			label: 'Topic',
			options: topicFilter
		},
		{
			name: 'type',
			placeholder: 'Source',
			label: 'Source',
			options: [
				{ value: 'danos', label: 'The Pipeline Newsletter' },
				{ value: 'foundation', label: 'Danos Foundation' }
			]
		}
	];
	let allData = data.allBlogPost.edges;
	let page = {};
	if (data.allContentPage.edges) {
		page = data.allContentPage.edges[0].node;
	}
	return (
		<>
			<Layout image={page.headerImage} preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab}>
				<SearchGrid apiController="blog/post" title="" description="" initialData={allData} searchCTA={searchCTA} filterOptions={filterOptions} />
			</Layout>
			<SEO title="The Pipeline Newsletter" description="" />
		</>
	)
}

export default BlogPostAll;