import React from "react";
import { graphql } from 'gatsby';

import BlogPostAll from "../templates/BlogPostAll";

const Blog = ({ data, pageContext }) => <BlogPostAll data={data} />

export default Blog;

export const query = graphql`
	query {
	  allBlogPost(sort: {order: DESC, fields: showDate}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	      	_model
	        id
	        photo
	        title
	        permalink
	        uri
	        intro
	        showDate
	      }
	    }
	  }
	  allBlogTopic(sort: {order: ASC, fields: name}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	      }
	    }
	  }
	  allContentPage(filter: {uri: {eq: "/danos-pipeline/"}, lang: {eq: "en"}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
			lang
	        pageTitle
			subTitle
			pullQuote
			pageCopy
			headline
			headerImage
			link
			linkText
			danosFoundation
			danosFooterTab
	      }
	    }
	  }
	}
`